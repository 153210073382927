import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { th, breakpoints } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import { H4, TitleContent, Text } from '@atoms/Typography'
import { ButtonLink } from '@atoms/Button'
import useBreakpoint from '@hooks/useBreakpoint'

const ExternalSection = ({ data, totalCareers }) => {
  const { sectionTitle, title, paragraphs, image, button, buttonLink } = data
  const { downTo } = useBreakpoint()
  return (
    <S.Section>
      <S.SectionTitle>{sectionTitle}</S.SectionTitle>
      <S.ContentContainer>
        <S.Image
          image={image.childImageSharp.gatsbyImageData}
          alt={sectionTitle}
        />
        <S.Content>
          <S.Title as="h3">{title}</S.Title>
          <S.Paragraphs>{paragraphs}</S.Paragraphs>
          <S.ButtonLink
            to={buttonLink}
            variant={downTo('md') ? 'green' : 'pink'}
          >
            {`${button} (${totalCareers})`}
          </S.ButtonLink>
        </S.Content>
      </S.ContentContainer>
    </S.Section>
  )
}

const S = {}

S.Section = styled.section`
  ${breakpoints({
    xs: css`
      padding-top: 50;
    `,
    md: css`
      padding-top: 138;
    `,
  })}
`

S.SectionTitle = styled(H4)`
  font-weight: ${th('fontWeights.normal')};
  ${breakpoints({
    xs: css`
      padding-bottom: 50;
    `,
    md: css`
      line-height: 1.3;
      padding-bottom: 74;
      font-size: 36;
    `,
  })}
`

S.ContentContainer = styled.div`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
    `,
    md: css`
      display: grid;
      grid-template-columns: 2fr 1.6fr;
      column-gap: 30;
    `,
  })}
`

S.Image = styled(GatsbyImage)`
  ${breakpoints({
    xs: css`
      object-fit: cover;
      width: 100vw;
      height: 250;
      max-width: 100vw;
      margin-left: -30;
      margin-right: -30;
      margin-bottom: 50;
    `,
    md: css`
      margin: 0;
      max-width: 486;
      height: 588;
    `,
    lg: css`
      height: 588;
    `,
    xl: css`
      height: 588;
    `,
  })}
`

S.Content = styled.div`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
    `,
    md: css`
      align-self: center;
      max-width: 486;
    `,
  })}
`

S.Title = styled(TitleContent)`
  ${breakpoints({
    xs: css`
      line-height: 1.5;
      margin-bottom: 20;
    `,
    md: css`
      line-height: 1.2;
      margin-bottom: 15;
      font-size: 48;
    `,
  })}
`

S.Paragraphs = styled(Text)`
  ${breakpoints({
    xs: css`
      margin-bottom: 30;
    `,
  })}
`

S.ButtonLink = styled(ButtonLink)`
  ${breakpoints({
    xs: css`
      align-self: flex-start;
    `,
  })}
`

export default ExternalSection
