import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { Text } from '@atoms/Typography'
import { ButtonLinkExternal } from '@atoms/Button'
import Divider from '@atoms/Divider'

const Book = ({ data, ...props }) => {
  const { button, buttonLink, paragraphs } = data
  return (
    <S.Container {...props}>
      <S.Text mb={{ xs: 20, md: 30 }}>{paragraphs}</S.Text>
      <S.ButtonLinkExternal
        as="a"
        href={buttonLink}
        target="_blank"
        variant="yellow"
      >
        {button}
      </S.ButtonLinkExternal>
      <S.Divider>OR</S.Divider>
    </S.Container>
  )
}

const S = {}

S.Container = styled.div`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 42;
    `,
    md: css`
      justify-content: space-between;
      align-items: flex-start;
      align-self: flex-start;
      margin-bottom: 0;
      max-width: 400;
      width: 105%;
      height: 100%;
    `,
  })}
`

S.Text = styled(Text)`
  ${breakpoints({
    xs: css`
      text-align: center;
    `,
    md: css`
      text-align: unset;
    `,
  })}
`

S.ButtonLinkExternal = styled(ButtonLinkExternal)`
  ${breakpoints({
    xs: css`
      margin-bottom: 42;
    `,
    md: css`
      margin-bottom: 0;
    `,
  })}
`

S.Divider = styled(Divider)`
  width: 100%;
`

export default Book
