import React from 'react'
import { isEmpty } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { H1 } from '@atoms/Typography'
import idgen from '@utils/idgen'
import Cta from './Cta'
import Book from './Book'
import Brief from './Brief'

const Hero = ({ data }) => {
  const { title, items } = data
  return (
    <S.Section>
      <S.Title as="h1">{title}</S.Title>
      <S.ContentContainer>
        {!isEmpty(items) &&
          items.map((item) => {
            switch (item.type) {
              case 'cta':
                return <Cta data={item} key={idgen()} />
              case 'book':
                return <Book data={item} key={idgen()} />
              case 'brief':
                return <Brief data={item} key={idgen()} />
              default:
                return null
            }
          })}
      </S.ContentContainer>
    </S.Section>
  )
}

const S = {}

S.Section = styled.section`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80;
    `,
    md: css`
      align-items: flex-start;
      margin-bottom: 178;
    `,
  })}
`

S.Title = styled(H1)`
  ${breakpoints({
    xs: css`
      width: 100%;
      text-align: center;
      font-weight: ${th('fontWeights.medium')};
      line-height: 1.2;
      margin-bottom: 20;
      letter-spacing: -0.02em;
    `,
    md: css`
      width: 100%;
      text-align: center;
      font-weight: ${th('fontWeights.medium')};
      font-size: 96;
      line-height: 1;
      margin-top: 162;
      margin-bottom: 146;
    `,
  })}
`

S.ContentContainer = styled.div`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    `,
    md: css`
      flex-direction: row;
      justify-content: space-around;
      & > div {
        margin: 0px 15px;
      }
    `,
    lg: css`
      /* column-gap: 24; */
    `,
  })}
`

export default Hero
