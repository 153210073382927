import React from 'react'
import { graphql } from 'gatsby'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { Container } from '@atoms/Grid'
import Layout from '@organisms/Layout'
import Hero from '@organisms/Contact/Hero'
import ExternalSection from '@organisms/Contact/ExternalSection'
import Section from '@molecules/StudiosSection'
import idgen from '@utils/idgen'

const Contact = ({ data }) => {
  const { blocks, meta } = data.contactPage.nodes[0].frontmatter
  const { careers } = data
  const totalCareers = careers && careers.nodes ? careers.nodes.length : 0

  return (
    <Layout
      hamburgerMenu
      title={meta.title}
      description={meta.description}
      invertColor
    >
      <S.Main>
        <S.Container>
          {blocks.map((item) => {
            switch (item.type) {
              case 'hero':
                return <Hero data={item} key={idgen()} />
              case 'section':
                return <Section data={item} key={idgen()} />
              case 'externalSection':
                return (
                  <ExternalSection
                    data={item}
                    totalCareers={totalCareers}
                    key={idgen()}
                  />
                )
              default:
                return null
            }
          })}
        </S.Container>
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};
  padding-top: 160;
  padding-bottom: 299;
`

S.Container = styled(Container)``

export const pageQuery = graphql`
  query ContactPage {
    contactPage: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "contact" } } }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
    careers: allMarkdownRemark(
      filter: {
        frontmatter: {
          collection: { eq: "careers" }
          status: { eq: "published" }
        }
      }
      sort: { order: DESC, fields: frontmatter___location }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
  }
`

export default Contact
