import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import idgen from '@utils/idgen'
import Studio from './Studio'

const Studios = ({ studios }) => {
  const isOdd = index => index % 2 !== 0
  return (
    <S.Wrapper>
      {studios.map((studio, index) => (
        <Studio key={idgen()} data={studio} isOdd={isOdd(index)} />
      ))}
    </S.Wrapper>
  )
}

export default Studios

const S = {}

S.Wrapper = styled.divBox`
  display: flex;
  justify-content: space-between;
  ${breakpoints({
    xs: css`
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
    `,
  })}
`
