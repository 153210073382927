import React from 'react'
import { H4 } from '@atoms/Typography'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import Studios from './Studios'

const Section = ({ data }) => {
  const { sectionTitle, items } = data
  return (
    <S.Container>
      <S.Title>{sectionTitle}</S.Title>
      <Studios studios={items} />
    </S.Container>
  )
}

export default Section

const S = {}

S.Container = styled.sectionBox``

S.Title = styled(H4)`
  padding-top: 20;
  font-weight: ${th('fontWeights.normal')};
  line-height: 1.3;
  letter-spacing: -0.02em;
  ${breakpoints({
    xs: css`
      padding-bottom: 50;
    `,
    md: css`
      padding-bottom: 89;
      font-size: 36;
    `,
  })}
`
