import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { th, breakpoints } from '@xstyled/system'
import { Text, TextLink } from '@atoms/Typography'

const Cta = ({ data, ...props }) => {
  const { email, phone } = data
  return (
    <S.Container {...props}>
      <Text mb={20}>
        <S.InfoLink as="a" href={`mailto:${email}`} target="_blank">
          {email}
        </S.InfoLink>
      </Text>
      <Text>
        <S.InfoLink href={`tel:${phone}`}>{phone}</S.InfoLink>
      </Text>
    </S.Container>
  )
}

const S = {}

S.Container = styled.div`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50;
    `,
    md: css`
      align-items: flex-start;
      align-self: flex-start;
      margin-bottom: 0;
    `,
  })}
`

S.InfoLink = styled(TextLink).attrs(() => ({
  invertColor: true,
}))`
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    md: css`
      font-size: 24;
      line-height: 1.4;
      font-weight: ${th('fontWeights.normal')};
    `,
  })}
`

export default Cta
