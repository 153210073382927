import React from 'react'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage } from "gatsby-plugin-image";
import { H4 } from '@atoms/Typography'

import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'

const Studio = ({ data, isOdd }) => {
  const { title, image, paragraphs } = data
  return (
    <S.Office key={title}>
      <x.div mb={{ xs: 15, md: isOdd ? 57 : 67 }}>
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} />
      </x.div>
      <S.Title as="h3" mb={0}>
        {title}
      </S.Title>
      <S.HTMLContent
        pr={{ md: isOdd ? 0 : 130 }}
        dangerouslySetInnerHTML={{
          __html: transformMDtoHTML(paragraphs),
        }}
      />
    </S.Office>
  );
}

export default Studio

const S = {}

S.Office = styled.divBox`
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      max-width: 45%;
    `,
    xl: css`
      max-width: 486;
    `,
  })}
`

S.Title = styled(H4)`
  ${breakpoints({
    md: css`
      font-size: 36;
    `,
  })}
`

S.HTMLContent = styled(HTMLContent)`
  font-weight: ${th('fontWeights.normal')};
  ${breakpoints({
    xs: css`
      margin-top: 10;
      margin-bottom: 50;
    `,
    md: css`
      font-size: 24;
      max-width: 384;
      margin-top: 15;
    `,
  })}
`
